<template>
  <div class="dashboard-container">
    <el-row :gutter="20">
      <el-col :span="16">
        <div
          class="item-comm statistics-warp"
          v-loading="overviewStatisLoading"
        >
          <div
            v-for="item in oneList"
            :key="item.key"
            class="statistics-item"
            @click="jumpInfo(item)"
          >
            <img :src="item.img" alt="" />
            <div class="amount-warp">{{ item.amount }}</div>
            <div class="name-warp">{{ item.name }}</div>
          </div>
        </div>
        <div
          class="item-comm statistics-warp"
          v-loading="overviewStatisLoading"
        >
          <div
            v-for="item in twoList"
            :key="item.key"
            class="statistics-item"
            @click="jumpInfo(item)"
          >
            <img :src="item.img" alt="" />
            <div class="amount-warp">{{ item.amount }}</div>
            <div class="name-warp">{{ item.name }}</div>
          </div>
        </div>
        <div
          class="item-comm statistics-chart-item"
          v-loading="tredeDayStatisLoading"
        >
          <div class="header">
            <div class="header-title">
              <div class="identification" />
              交易流水（笔）
            </div>
            <div class="save-move-warp" @click="jumpTradingList">
              查看更多数据统计
            </div>
          </div>
          <div id="my-charts" ref="myChart" class="my-charts"></div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="item-comm">
          <div class="QRCode-warp">
            <img src="@/assets/app-code.png" alt="app" />
            <img src="@/assets/applet.jpg" alt="小程序" />
          </div>
          <div class="QRCode-msg-warp">
            门店、供应商、酒店、企业管理、区域代理等独立后台登录域名同总平台，使用对应的店员账号密码即可，注意不能使用同一浏览器登录
          </div>
        </div>
        <div class="item-comm note-warp">
          <div class="header">
            <div class="identification" />
            更新通知
          </div>
          <div class="content">
            <div v-for="item in noteList" :key="item.key" class="content-item">
              <div class="content-header">
                <div class="title">{{ item.title }}</div>
                <div class="time">{{ item.time }}</div>
              </div>
              <div class="content-info">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import echarts from "echarts";
import { getOverviewStatis, getTredeDayStatis } from "@/api/dashboard";

export default {
  name: "Dashboard",
  data() {
    return {
      oneList: [
        {
          key: 1,
          img: require("@/assets/user.png"),
          name: "用户",
          amount: 0,
          path: "/account/user"
        },
        {
          key: 2,
          img: require("@/assets/member.png"),
          name: "会员",
          amount: 0,
          path: ""
        },
        {
          key: 3,
          img: require("@/assets/equipment.png"),
          name: "设备",
          amount: 0,
          path: "/equipment/device"
        },
        {
          key: 4,
          img: require("@/assets/amount.png"),
          name: "成交金额",
          amount: 0,
          path: "/financial/payment"
        },
        {
          key: 5,
          img: require("@/assets/runningTab.png"),
          name: "交易流水",
          amount: 0,
          path: "/financial/payment"
        }
      ],
      twoList: [
        {
          key: 1,
          img: require("@/assets/points.png"),
          name: "积分",
          amount: 0,
          path: "/financial/user-credit"
        },
        {
          key: 2,
          img: require("@/assets/store.png"),
          name: "代理门店",
          amount: 0,
          path: "/store/list"
        },
        {
          key: 3,
          img: require("@/assets/order.png"),
          name: "商城订单",
          amount: 0,
          path: ""
        },
        {
          key: 4,
          img: require("@/assets/repair.png"),
          name: "设备维修",
          amount: 0,
          path: "/equipment/warranty-list"
        },
        {
          key: 5,
          img: require("@/assets/feedback.png"),
          name: "异常反馈",
          amount: 0,
          path: ""
        },
      ],
      noteList: [
        {
          key: 1,
          title: "【幸福会小程序】全新升级通知",
          time: "2024-10-10",
          content:
            "为了给您带来更加舒适和便捷的使用体验，我们于今日2024年10月10日对按摩椅小程序进行了全面升级。"
        },
        {
          key: 2,
          title: "【幸福会小程序】功能更新通知",
          time: "2024-6-30",
          content:
            "在这个充满活力的夏季，我们于2024年6月30日再次为按摩椅小程序带来了丰富的功能更新。"
        },
        {
          key: 3,
          title: "【幸福会小程序】V3.2版本震撼发布",
          time: "2024-5-16",
          content:
            "按摩椅小程序V3.2版本已于今日正式发布！此次版本更新，我们带来了众多新功能和优化，旨在为您带来更加出色的按摩体验。"
        }
      ],
      overviewStatisLoading: false,
      tredeDayStatisLoading: false,
      tredeDayStatisList: []
    };
  },
  mounted() {
    this.getOverviewStatisFun();
    this.getTredeDayStatisFun();
  },
  methods: {
    getOverviewStatisFun() {
      this.overviewStatisLoading = true;
      getOverviewStatis()
        .then(resp => {
          if (resp && resp.code === 0) {
            const data = resp.data;
            this.oneList[0].amount = data.user_count; //用户数
            this.oneList[1].amount = data.vip_count; //会员
            this.oneList[2].amount = data.devices_count; //设备
            this.oneList[3].amount = data.trade_amount; //成交金额
            this.oneList[4].amount = data.order_count; // 交易流水

            this.twoList[0].amount = data.integral_amount; //积分
            this.twoList[1].amount = data.store_count; //代理门店
            this.twoList[3].amount = data.baoxiu_count; //设备维修
          }
        })
        .finally(() => {
          this.overviewStatisLoading = false;
        });
    },
    getTredeDayStatisFun() {
      this.tredeDayStatisLoading = true;
      getTredeDayStatis()
        .then(resp => {
          if (resp && resp.code === 0) {
            this.tredeDayStatisList = resp.data;
            this.initEcharts();
          }
        })
        .finally(() => {
          this.tredeDayStatisLoading = false;
        });
    },
    initEcharts() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("my-charts"));
      const data = this.tredeDayStatisList;
      let option = {
        xAxis: {
          type: "category",
          tooltip: {
            trigger: "axis"
          },
          legend: {
            data: ["交易流水"]
          },
          data: data.map(item => item.date_str)
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            name: "交易流水",
            stack: "Total",
            data: data.map(item => item.cc),
            type: "line"
          }
        ]
      };
      option && myChart.setOption(option);
    },
    // 交易流水页面
    jumpTradingList() {
      this.$router.push("/financial/payment");
    },
    // 跳转到详情
    jumpInfo(row) {
      console.log("row", row);
      if (row.path) {
        this.$router.push(row.path);
      }
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.dashboard-container {
  height: calc(100vh - 124px);
  background-color: #f5f5f5;
  padding: 20px;
  .item-comm {
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 13px -6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
  }
  .statistics-warp {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    padding: 40px 120px;
  }
  .statistics-item {
    cursor: pointer;
    width: 20%;
  }
  .amount-warp {
    font-weight: 600;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 33px;
  }
  .name-warp {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
  }
  .QRCode-warp {
    display: flex;
    justify-content: space-around;
    img {
      width: 170px;
    }
  }
  .QRCode-msg-warp {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 20px;
    padding: 20px 30px 0;
  }
  .statistics-chart-item {
    .header {
      display: flex;
      justify-content: space-between;
    }
    .header-title {
      display: flex;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
    }
    .save-move-warp {
      color: #1890ff;
      line-height: 22px;
      cursor: pointer;
    }
    .my-charts {
      height: calc(100vh - 592px);
      width: 100%;
    }
  }
  .note-warp {
    padding: 30px 20px 20px;
    .header {
      display: flex;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 20px;
    }
  }

  .identification {
    width: 4px;
    height: 20px;
    background: #1a5fff;
    margin-right: 12px;
  }
  .content {
    height: calc(100vh - 506px);
    overflow-y: auto;
    .content-item {
      background: #ffffff;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      border: 1px solid rgba(24, 144, 255, 0.5);
      padding: 10px;
      margin-bottom: 14px;
    }
    .content-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 22px;
    }
    .title {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
    .time {
      color: rgba(0, 0, 0, 0.5);
    }
    .content-info {
      font-size: 11px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 20px;
    }
  }
}
</style>
