import request from "@/utils/request";

/**
 * 查询概况统计
 * @returns {AxiosPromise}
 */
export function getOverviewStatis() {
  return request({
    url: "/api/backend/dashboard/getOverviewStatis",
    method: "get",
    params: {}
  });
}

/**
 * 查询交易流统计
 * @returns {AxiosPromise}
 */
export function getTredeDayStatis() {
  return request({
    url: "/api/backend/dashboard/getTredeDayStatis",
    method: "get",
    params: {}
  });
}
